/*
 * @Descripttion:
 * @version:
 * @Author: captern@icloud.com
 * @Date: 2022-07-07 17:56:38
 * @LastEditors: captern
 * @LastEditTime: 2022-07-26 10:33:06
 */
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
);

reportWebVitals();
