import React, { useState } from "react";
import { useSelector } from "react-redux";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import About from "../components/About/About";
import ThreeApp from "../components/ThreeApp/ThreeApp";

import Blog from "../components/Blog/Blog";
import Career from "../components/Career/Career";
import "./home.scss";
import bg from "@assets/img/bg.png";
import point from '@assets/img/point.png';
import { Routes, Route} from 'react-router-dom'
const Home: React.FC = () => {
  
  return (
    <div className="container"  style={{backgroundImage: `url(${bg})`, cursor: `url(${point}) 12 20, auto` }}>
      <Header />
      <Routes>
        <Route path="/" element={<ThreeApp/>}/>
        {/* <Route path="/career" element={<Career/>}/> */}
        {/* <Route path="/about" element={<About/>}/> */}
        {/* <Route path="/blog" element={<Blog/>}/> */}
      </Routes>
      <Footer />
    </div>
  );
};
export default Home;
