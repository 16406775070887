import React, { useEffect, useState } from "react";
import { Anchor } from "antd";
import { Link as RLink} from 'react-router-dom';
import "./header.scss";
import logo from "@assets/img/logo.png";
const { Link } = Anchor;
const Header: React.FC = (props: any) => {
  return (
    <div className="header-container">
      <div className="header-main">
        <div className="header-left" >
          <RLink to="/">
            <img className="logo"  src={logo} alt="" />
          </RLink>
        </div>
        <div className="header-center">
          <Anchor>
            {/* <Link href="about" title="About Us" /> */}
            {/* <Link href="blog" title="Blog" />
            <Link href="career" title="Career" /> */}
          </Anchor>
        </div>
      </div>
    </div>
  );
};
export default Header;
