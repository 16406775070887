import React, { useEffect, useState } from "react";
import { Anchor } from "antd";
import { Link as RLink} from 'react-router-dom';
import "./footer.scss";
import logo from "@assets/img/logo.png";
const { Link } = Anchor;
const Footer: React.FC = (props: any) => {
  return (
    <div className="footer-container">
      <div className="footer-main">
        <div className="footer-left" >
            @2023 VAST. ALL RIGHTS RESERVED.
        </div>
        <div className="footer-right">
            GET IN TOUCH: contact@vast3d.ai
        </div>
      </div>
    </div>
  );
};
export default Footer;
